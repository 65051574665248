import $ from "jquery";
import bs58 from "bs58";
import Cookies from "js-cookie";
import ecc from "eosjs-ecc";
import { Api, JsonRpc, RpcError, JsSignatureProvider, Key } from "@proton/js";

import { apiEndpoint, apiDevEndpoint } from "constants";
import RPC from "assets/javascript/web3RPC.ts";

import * as Proton from "assets/javascript/proton";

const rpc = new JsonRpc(["https://proton.eosusa.io", "https://api-proton.saltant.io", "https://proton.pink.gg"]);

export async function connectWallet(state, dispatch, w3a) {
    const web3auth = (w3a) ? web3auth : state.w3a;

    if (!web3auth) {
        console.error("web3auth not initialized yet");

        return;
    };

    setTimeout(() => {
        $(".w3a-header__title").html("Log In");

        $(".w3ajs-passwordless-form input[name='passwordless-input']").attr("placeholder", "example@domain.com").on("focusout", e => {
            setTimeout(() => {
                $(e.target).attr("placeholder", "example@domain.com");
            }, 1);
        });

        $(".w3a-modal__content > .w3ajs-external-wallet").remove();

        $(".w3ajs-external-wallet button.w3a-button").on("click", e => {
            $(".w3ajs-close-btn").trigger("click");

            $(e.target).blur();
        });
    }, 30);

    var web3authProvider = (web3auth.connected) ? web3auth.provider : await web3auth.connect();

    // fetch account name from blockchain via public key

    dispatch({
        type: "setW3AProvider",
        value: {
            provider: web3authProvider,
            connected: web3auth.connected,
            pubKey: await publicKey(web3auth.provider),
            privKey: await privateKey(web3auth.provider),
            user: await web3auth.getUserInfo(),
            session: { auth: { actor: "testacc1", permission: "active" }, link: { walletType: "paymex" } }
        }
    });
};

export async function disconnectWallet(state, dispatch) {
    const web3auth = state.w3a;

    if (!web3auth) {
        console.error("web3auth not initialized yet");

        return;
    };

    await web3auth.logout();

    dispatch({
        type: "setW3AProvider",
        value: {
            provider: null,
            connected: false,
            pubKey: null,
            privKey: null
        }
    });

    dispatch({
        type: "setAuth",
        value: null
    });

    dispatch({
        type: "setWallet",
        value: null
    });
};

export async function publicKey(provider) {
    var privKey = await privateKey(provider);

    var isValid = ecc.PrivateKey.isValid(privKey);

    if (!isValid) return Promise.reject("privKey: Invalid");

    var pubKey = ecc.PrivateKey.fromString(privKey).toPublic().toString();

    return pubKey;
};

export async function privateKey(provider) {
    const rpc = new RPC(provider);
    
    const ethPrivKey = await rpc.getPrivateKey();

    var extKey = "80" + ethPrivKey;

    var extKeyHash = ecc.sha256(Buffer.from(extKey, "hex"));

    var extKeyHash2 = ecc.sha256(Buffer.from(extKeyHash, "hex"));

    var extKeyResult = extKey + extKeyHash2.substring(0, 8).toUpperCase();

    let encodedKey = Buffer.from(extKeyResult, "hex");

    return bs58.encode(encodedKey);
};

export async function signMessage(state, message) {
    const privKey = state.w3aPrivateKey;

    if (!privKey) return Promise.reject("w3aPrivateKey: Not Initialized");

    const signedMessage = await ecc.sign(message, privKey);

    return signedMessage;
};

export async function recoverKey(signature, message) {
    const publicKey = await ecc.recover(signature, message);

    return publicKey;
};

export async function authenticateSignature(signature, timestamp) {
    if (!signature) return Promise.reject("signature: Undefined");
    if (!timestamp) return Promise.reject("timestamp: Undefined");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/v1/auth/verify",
        xhrFields: {
            withCredentials: true
        },
        data: {
            signature: signature,
            timestamp: timestamp
        },
        error: function(res) {
            if (res.status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");
        }
    });
};

export async function accountExists(username) {
    if (!username) return Promise.reject("username: Undefined");

    try {
        await rpc.get_account(username);

        return true;
    } catch(error) {
        return false;
    };
};

export async function createAccount(state, dispatch, user, username) {
    if (!state.w3aConnected) return connectWallet(state, dispatch);

    const timestamp = Math.floor(Date.now());

    const signature = await signMessage(state, "request jwt " + timestamp);

    if (!user) return $("div.error-modal").addClass("error").find("div.text").html("user: Undefined");
    if (!username) return $("div.error-modal").addClass("error").find("div.text").html("username: Undefined");

    return $.ajax({
        type: "POST",
        url: apiEndpoint + "/v1/account/create",
        xhrFields: {
            withCredentials: true
        },
        data: {
            user: JSON.stringify(user),
            username: username,
            signature: signature,
            timestamp: timestamp
        },
        error: function({ responseJSON, status, statusText }) {
            if (status === 0) return $("div.error-modal").addClass("error").find("div.text").html("There was an error processing your request, please try again (status 0)");

            $("div.error-modal").addClass("error").find("div.text").html(responseJSON);
        }
    });
};