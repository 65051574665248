import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { } from "constants";
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

export default function MenuBar() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});

    async function handleConnect() {
        try {
            const web3auth = new Web3Auth({
                clientId: "BG1jyVVq_Ff0Qh8yo6esMOY69i5VyGId-V8CwWIkfnTE1HgcN86xz9rjB23SfsjNzEHvV-AfGNpLJ_lFMBS-5dA",
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.EIP155,
                    chainId: "0x1",
                    rpcTarget: "https://mainnet.infura.io/v3/b955490948ba4ad2a3ef078a8a527a19",
                },
                uiConfig: {
                    appName: "Paymex",
                    appLogo: "https://storex.io/assets/images/paymex_120.png",
                    theme: "light",
                    loginMethodsOrder: ["google", "twitter", "facebook", "discord", "apple", "github", "reddit", "linkedin", "twitch"],
                    defaultLanguage: "en",
                    loginGridCol: 3,
                    primaryButton: "emailLogin"
                },
                web3AuthNetwork: "mainnet"
            });

            const openloginAdapter = new OpenloginAdapter({
                loginSettings: {
                    mfaLevel: "mandatory",
                },
                adapterSettings: {
                    uxMode: "redirect",
                    whiteLabel: {
                        appName: "Paymex",
                        appUrl: "https://storex.io",
                        name: "Paymex",
                        logoLight: "https://storex.io/assets/images/paymex_120.png",
                        logoDark: "https://storex.io/assets/images/paymex_120.png",
                        defaultLanguage: "en",
                        dark: false,
                        theme: {
                            primary: "#05063f"
                        }
                    },
                    mfaSettings: {
                        passwordFactor: {
                            enable: true,
                            priority: 1,
                            mandatory: true,
                        },
                        deviceShareFactor: {
                            enable: false
                        },
                        socialBackupFactor: {
                            enable: false
                        },
                        backUpShareFactor: {
                            enable: false,
                        },
                    },
                }
            });

            web3auth.configureAdapter(openloginAdapter);

            dispatch({
                type: "setW3A",
                value: web3auth
            });

            await web3auth.initModal({
                modalConfig: {
                    [WALLET_ADAPTERS.OPENLOGIN]: {
                        label: "openlogin",
                        loginMethods: {
                            sms_passwordless: {
                                name: "sms_passwordless",
                                showOnModal: false
                            }
                        }
                    },
                    [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
                        label: "wallet_connect",
                        showOnModal: false
                    },
                    [WALLET_ADAPTERS.METAMASK]: {
                        label: "metamask",
                        showOnModal: false
                    }
                }
            });

            if (!web3auth.connected) await web3auth.connect();

            // fetch account name from blockchain via public key

            dispatch({
                type: "setW3AProvider",
                value: {
                    provider: web3auth.provider,
                    connected: true,
                    pubKey: await Paymex.publicKey(web3auth.provider),
                    privKey: await Paymex.privateKey(web3auth.provider),
                    user: await web3auth.getUserInfo(),
                    session: { auth: { actor: "testacc1", permission: "active" }, link: { walletType: "paymex" } }
                }
            });
        } catch(error) {
            console.error(error);
        };
    };

    useEffect(() => {
        handleConnect();
    }, []);

    useEffect(() => {
        if (state.w3aConnected == null) return;

        if (!state.w3aConnected) return Paymex.connectWallet(state, dispatch);
    }, [state.w3aConnected]);

    return (
        <>
            {
                (state.w3aConnected) && (
                    <div className="menu-bar">
                        <Link to="/">
                            <div className={ (window.location.pathname == "/") ? "icon-wrapper selected" : "icon-wrapper" }>
                                <i className="fal fa-wallet"></i>
                                <p>Wallet</p>
                            </div>
                        </Link>

                        <Link to="/trade">
                            <div className={ (window.location.pathname == "/trade") ? "icon-wrapper selected" : "icon-wrapper" }>
                                <i className="fal fa-exchange"></i>
                                <p>Trade</p>
                            </div>
                        </Link>

                        <Link to="/settings">
                            <div className={ (window.location.pathname == "/settings") ? "icon-wrapper selected" : "icon-wrapper" }>
                                <i className="fal fa-user-cog"></i>
                                <p>Settings</p>
                            </div>
                        </Link>
                    </div>
                )
            }
        </>
    );
};
