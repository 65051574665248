import { React } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "pages/index";
import Trade from "pages/trade";
import Settings from "pages/settings";
import DisplayName from "pages/settings/display-name";
import Language from "pages/settings/language";
import Currency from "pages/settings/currency";
import PrivateKey from "pages/settings/private-key";
import LinkedSites from "pages/settings/linked-sites";

import ActionModal from "components/action-modal";
import MenuBar from "components/menu-bar";

//import UserAgreement from "pages/user-agreement";
//import PrivacyNotice from "pages/privacy-notice";

function Main() {
    return (
        <main>
            <ActionModal/>

            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/trade" element={<Trade/>}/>
                <Route exact path="/settings" element={<Settings/>}/>
                <Route exact path="/settings/display-name" element={<DisplayName/>}/>
                <Route exact path="/settings/language" element={<Language/>}/>
                <Route exact path="/settings/currency" element={<Currency/>}/>
                <Route exact path="/settings/private-key" element={<PrivateKey/>}/>
                <Route exact path="/settings/linked-sites" element={<LinkedSites/>}/>

                <Route path="*" element={<Home/>}/>
            </Routes>

            <MenuBar/>
        </main>
    );
};

export default Main;
