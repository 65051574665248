import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { } from "constants";
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

export default function LinkedSites() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        if (state.w3aConnected == null) return;

        if (!state.w3aConnected) return navigate("/");
    }, [state.w3aConnected]);

    function handleDisplayName() {
        
    };

    return (
        <>
            <section className="settings">
                {
                    (state.w3aConnected == null) && (
                        <div className="loading">
                            <i className="fad fa-spinner-third"></i>
                        </div>
                    )
                }

                {
                    (state.w3aConnected) && (
                        <>
                            <div className="user-overview full-width">
                                <button className="back" onClick={ () => navigate("/settings") }><i className="fas fa-chevron-left"/></button>

                                <div className="img-wrapper">
                                    <img src={ (state.w3aUser?.profileImage) ? state.w3aUser?.profileImage : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAbFBMVEUAAABXV1dXV1dYWFhYWFhYWFhYWFhXV1dQUFBXV1dXV1dWVlZYWFhWVlZXV1dYWFhWVlZXV1dWVlZXV1f////19fW1tbXq6upiYmKWlpZsbGyrq6vf39/V1dWBgYHLy8vAwMChoaGLi4t3d3cxypPpAAAAE3RSTlMA3+9AIXBgvxCvn8+/UJCAX49/5ySm/wAAA5hJREFUaN7U1tdy2zAQBVAUkmCJYsuXvUiW9P//mGQQeW1ZNrEQgRmfN+Llzi4XRbDlOtuXRkqFf2RliqdU5yIsnRYSd8ki1SKMPDUK31JFlgRIhROzabYuFdyVeqtYA6Yq2yJWwoPMIldLpBb+EoMHlN5jlio8RL1ELpfIJG65RKWCJ99jI3temyuQiO1OJEjE5J3CptROOHnG5p4ZuVGTKTd+8g6B7FbmWSEQlXD2UbRdJRFQlYuv7LEi0OmZIrCUMVgxBkwiOCPueEEEKaPRoZtdYkWgZmuw9Id2buq6WdozePQjk9VPTf2mm0YQbskZGE42lqIHMGjvgtv6kxbupG/Br/UdryCckiuAW69/zcZrpIdr0jKMwGU4Xr9PcKY99vDY2ZjucLvS9HBVXnMTOJtsykwp6Ge7NsGVyvmj1dl6e4D0HbfkVFgGrs62uBEfHOzqAa4MdZo10kfcWLiDnXM6TQnD/VGfwex1AWf2rLzgxmh/MsDrtYIz+zNX1h0oOj3iBkMz35YNDfV7F26rkfJ+MebvhmuBu4J7I95POPrcjTlWrB8Vo109gyEXGgx98/WR2YFD02zFuCRIJp5gsUquu/Gt+x2tMPwWBVhO9X/H4QKMw3L9HsBSCgMS/OlDfokKJPhjj8jbbRz6eUvB4Bu6+oPmBDYl4GGcunexUw8PAn7O7dL8DZ3bQw8vAg/7acF/qjebHQdhGAzaBLLhR3BA7YXL7vu/5B5Q6/aAGCiYdM5RR6YqST67xXgNouMl6FXi1l6ZvnT4wvbIPRbYmofU0lPttCjdk4cM9B7xB7Rb8pAKHn3uTMs3jMAOe79QyrfIJKKw3kNrVnKgf889FtiYh9TgCvOae6zA85CKXNrsSLsGP+oGcE213AMA85ACRCCWewBoHlKDHojlHgSYh8S5O04+Cb6QYB5SgrjJcg8Cy0M60m/iMQNfH2UmFc7i0kJUV3FjsbGvOMiTH09xK0bwFEd5Qf3ESvtdNxCewfVWMPmWJ7K38/XKW3z3220CQrg+mNNKdqDJp40r1ejAIIbnw9a8hhPcxzGMfjyVXpZI7XgimuOQ0aljVXkOksGG33aiiLvZvP7mmPdA6J5fVY7Dt615Aak/ytun7xjsPuhxd6XsYSh8yzXKxrlcI+hubZDPiLvUGuRzYutcrRGakVM0QIspYweLrZJAuLsuVmp9s37/n+kepFANddfqXKRq1/QxbK/0HyrzSufhQXNFAAAAAElFTkSuQmCC" } alt=""/>
                                </div>
                                
                                <div className="text-wrapper">
                                    <p className="name">{ state.w3aUser?.name }</p>
                                    <p className="username">@{ state.session?.auth.actor }</p>
                                </div>
                            </div>

                            <div className="menu-wrapper">
                                <div className="subtitle">Linked Websites</div>

                                <ul className="menu">
                                    
                                </ul>
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};