import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { } from "constants";
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

export default function Trade() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        if (state.w3aConnected == null) return;

        if (!state.w3aConnected) return navigate("/");
    }, [state.w3aConnected]);

    return (
        <>
            <section className="index">
                {
                    (state.w3aConnected == null) && (
                        <div className="loading">
                            <i className="fad fa-spinner-third"></i>
                        </div>
                    )
                }

                {
                    (state.w3aConnected) && (
                        <>
                            
                        </>
                    )
                }
            </section>
        </>
    );
};