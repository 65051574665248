import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams  } from "react-router-dom";
import $ from "jquery";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { supportedCurrencies } from "constants";
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

var supportedCurrenciesCopy = [...supportedCurrencies];

export default function Index() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (state.w3aConnected == null) return;

        if (!state.w3aConnected) return navigate("/");

        if (state.w3aConnected) return handleDataFetch();
    }, [state.w3aConnected]);

    useEffect(() => {
        if (!data.loaded) return;
        
        if (searchParams.size < 1) return;

        var urlAction = searchParams.get("action");
        
        switch (urlAction) {
            case "connect":
                var url = searchParams.get("site");

                if (!url) {
                    window.opener.postMessage({ type: "connectfail", error: { details: [{ message: "missing param: site" }] } }, "*");

                    return window.close()
                };

                $("div.action-modal").addClass("active");

                dispatch({
                    type: "setModal",
                    value: {
                        action: "connect",
                        data: {
                            name: searchParams.get("name"),
                            logo: searchParams.get("logo"),
                            site: url,
                        }
                    }
                });

                break;

            case "transact":
                var url = searchParams.get("site");

                if (!url) {
                    window.opener.postMessage({ type: "txfail", error: { details: [{ message: "missing param: site" }] } }, "*");

                    return window.close()
                };

                var siteConnections = localStorage.getItem("paymex-siteConnections") || [];

                if (!siteConnections?.includes(url)) {
                    window.opener.postMessage({ type: "txfail", error: { details: [{ message: "site has not been connected to Paymex Wallet" }] } }, "*");

                    return window.close()
                };

                $("div.action-modal").addClass("active");

                dispatch({
                    type: "setModal",
                    value: {
                        action: "transact",
                        data: {
                            name: searchParams.get("name"),
                            logo: searchParams.get("logo"),
                            site: url,
                            actions: [{
                                account: "xtokens",
                                name: "transfer",
                                data: {
                                    from: state.session?.auth.actor,
                                    to: "strxdev",
                                    quantity: "0.000001 FOOBAR",
                                    memo: ""
                                },
                                authorization: [state.session.auth]
                            }],
                            idempotentKey: Math.floor(Math.random() * 1000000)
                        }
                    }
                });
                // check if localStorage contains site connection

                // show transaction modal from bottom of screen

                // send transaction to blockchain
                break;

            default:
                window.close();

                break;
        };
    }, [data.loaded]);

    window.addEventListener("message", event => {
        if (event.data.type == "txsuccess") {
            alert("Tx Success: " + event.data?.tx?.transaction_id);
        } else if (event.data.type == "txfail") {
            alert("Tx Fail: " + event.data?.error?.details?.[0]?.message);
        } else if (event.data.type == "connectsuccess") {
            alert("Successfully connected to Paymex Wallet");
        } else if (event.data.type == "connectfail") {
            alert("Failed to connect Paymex Wallet");
        };
    });

    function handleDataFetch() {
        var balances = {};
        var prices = {};
        var oracles = {};

        Proton.fetchOracles().then(res => {
            var oracleArr = res?.rows || [];

            oracleArr.forEach((feed, index) => {
                var feedIndex = feed?.feed_index || 0;
                var feedPrice = feed?.aggregate?.d_double || 0;

                oracles[feedIndex] = feedPrice;

                if (index == oracleArr.length-1) {
                    supportedCurrenciesCopy.forEach((currency, index) => {
                        Proton.fetchBalance(state.session.auth.actor, currency).then(res => {
                            const [balanceStr] = res;
                            const [balance, ticker] = (balanceStr || "").split(" ");
            
                            balances[currency.ticker] = balance || "0";

                            prices[currency.ticker] = oracles[currency.feedIndex];

                            if (index == supportedCurrenciesCopy.length-1) {
                                Proton.fetchStorexOracles().then(res => {
                                    var oracleArr = res?.rows || [];
                        
                                    oracleArr.forEach((feed, index) => {
                                        const {sym, quantity} = feed;
                                        const [, ticker] = sym.split(",");
                                        const [amount] = quantity.split(" ");
                        
                                        prices[ticker] = amount;

                                        if (index == oracleArr.length-1) {
                                            setData(values => (
                                                { ...values, balances: balances, prices: prices, loaded: true }
                                            ));
                                        };
                                    });
                                }).catch(err => {
                                    console.log(err);
                                });
                            };
                        }).catch(err => {
                            console.log(err);
                        });
                    });
                };
            });
        }).catch(err => {
            console.log(err);
        });

        Proton.fetchDepositAddresses(state.session).then(res => {
            if (!res.rows) return;

            var addressObj = {};

            res.rows.forEach(row => {
                addressObj[row.chain] = row.address;
            });

            setData(values => (
                { ...values, depositAddresses: addressObj }
            ));
        }).catch(err => {
            console.log(err);
        });
    };

    function handleSend() {
        // window.paymexPopup = window.open(
        //     `https://paymex.storex.io/?action=connect&name=${ "Storex" }&logo=${ "https://localhost.storex.io/assets/images/strx_storex_256.png" }&site=${ window.location.hostname }`,
        //     "Paymex Wallet",
        //     "left=60,top=140,width=424,height=700",
        // );

        window.paymexPopup = window.open(
            `https://paymex.storex.io/?action=transact&name=${ "Storex" }&logo=${ "https://localhost.storex.io/assets/images/strx_storex_256.png" }&site=${ window.location.hostname }`,
            "Paymex Wallet",
            "left=60,top=140,width=424,height=700",
        );

        if (!window.paymexPopup) {
            alert("Paymex Wallet unable to open - enable popups and try again");
        };
    };

    function handleReceive() {
        window.paymexPopup?.close();
    };

    return (
        <>
            <section className="index">
                {
                    (!state.w3aConnected || !data.loaded) && (
                        <div className="loading">
                            <img src="./assets/images/paymex_logo.svg" alt="" />

                            <i className="fad fa-spinner-third"></i>
                        </div>
                    )
                }

                {
                    (state.w3aConnected && data.loaded) && (
                        <>
                            <div className="balance-overview full-width">
                                <div className="text-wrapper">
                                    <p>Balance</p>
                                    <div className="balance">
                                        {
                                            parseFloat(supportedCurrenciesCopy.reduce((a, b) => a + (data.prices?.[b.ticker] * data.balances?.[b.ticker]), 0) || 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 })
                                        }
                                    </div>
                                </div>

                                <div className="button-wrapper">
                                    <button onClick={ handleSend }>
                                        <i className="far fa-arrow-from-bottom"></i>
                                        <p>Send</p>
                                    </button>

                                    <button onClick={ handleReceive }>
                                        <i className="far fa-arrow-to-bottom"></i>
                                        <p>Receive</p>
                                    </button>
                                </div>
                            </div>

                            <div className="token-overview">
                                <div className="subtitle">Tokens</div>

                                <ul className="token-wrapper">
                                    {
                                        supportedCurrenciesCopy.sort((a, b) => (data.prices?.[b.ticker] * data.balances?.[b.ticker]) - (data.prices?.[a.ticker] * data.balances?.[a.ticker])).map((currency, index) => (
                                            <li>
                                                <div className="img-wrapper">
                                                    <img src={ currency.logo } alt={ currency.ticker } />
                                                </div>

                                                <div className="text-wrapper">
                                                    <p className="ticker">{ currency.ticker }</p>
                                                    <p className="value">{ parseFloat(data.prices?.[currency.ticker] || 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: (parseFloat(data.prices?.[currency.ticker] || 0 * data.balances?.[currency.ticker] || 0) >= 1) ? 2 : 6 }) }</p>
                                                </div>

                                                <div className="balance-wrapper">
                                                    <p className="total">{ parseFloat((data.prices?.[currency.ticker] || 0) * (data.balances?.[currency.ticker] || 0)).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</p>
                                                    <p className="balance">{ parseFloat(data.balances?.[currency.ticker] || 0).toLocaleString("en-US", { minimumFractionDigits: currency.precision }) } { currency.ticker }</p>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </>
                    )
                }
            </section>
        </>
    );
};