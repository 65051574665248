import { React } from "react";
import { BrowserRouter } from "react-router-dom";
import $ from "jquery";

import { StateProvider } from "state";
import { defaultSession, defaultAccount } from "constants";

import Main from "components/main";

function App() {
    Number.prototype.toFixedStatic = function(p) {
        return Math.floor(this * Math.pow(10, p)) / Math.pow(10, p);
    };

    $.fn.hasAnyClass = function() {
        for (var i = 0; i < arguments.length; i++) {
            var classes = arguments[i].split(" ");

            for (var j = 0; j < classes.length; j++) {
                if (this.hasClass(classes[j])) {
                    return true;
                };
            };
        };

        return false;
    };

    const state = {
        account: defaultAccount,
        authenticated: null,
        connected: null,
        currency: "USD",
        language: "EN",
        link: null,
        session: defaultSession,
        w3a: null,
        w3aConnected: null,
        w3aPrivateKey: null,
        w3aProvider: null,
        w3aPublicKey: null,
        w3aUser: null
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "setW3A":
                return {
                    ...state,
                    w3a: action.value
                }

            case "setW3AProvider":
                return {
                    ...state,
                    w3aProvider: action.value ? action.value.provider : null,
                    w3aConnected: action.value ? action.value.connected : null,
                    w3aPublicKey: action.value ? action.value.pubKey : null,
                    w3aPrivateKey: action.value ? action.value.privKey : null,
                    w3aUser: action.value ? action.value.user : null,
                    session: action.value ? action.value.session : defaultSession,
                }

            case "setLanguage":
                return {
                    ...state,
                    language: action.value ? action.value : null
                };

            case "setModal":
                return {
                    ...state,
                    actionModal: action.value
                }

            case "promptLogin":
                return {
                    ...state,
                    promptLogin: action.value
                }

            case "promptRedirect":
                return {
                    ...state,
                    promptRedirect: action.value
                }

            case "connectionAuthorized":
                return {
                    ...state,
                    connectionAuthorized: action.value
                }

            default:
                return state;
        };
    };

    return (
        <BrowserRouter>
            <StateProvider state={ state } reducer={ reducer }>
                <Main/>
            </StateProvider>
        </BrowserRouter>
    );
};

export default App;