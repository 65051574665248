import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";

import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import { useStateValue } from "state";
import { } from "constants";
import { textStrings } from "localization";

import * as Proton from "assets/javascript/proton";
import * as Paymex from "assets/javascript/paymex";

export default function ActionModal() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({});
    const [data, setData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!state.actionModal) return;

        if (state.actionModal?.action == "connect") {
            setData(values => (
                { ...values, appName: state.actionModal?.data.name, appLogo: state.actionModal?.data.logo, appSite: state.actionModal?.data.site }
            ));
        };

        if (state.actionModal?.action == "transact") {
            setData(values => (
                { ...values, appName: state.actionModal?.data.name, appLogo: state.actionModal?.data.logo, appSite: state.actionModal?.data.site, actions: state.actionModal?.data.actions, idempotentKey: state.actionModal?.data.idempotentKey }
            ));
        };
    }, [state.actionModal]);

    function handleAuthorize() {
        if (state.actionModal?.action == "connect") {
            var success = function() {
                $("div.action-modal, section").removeClass("active");

                window.opener.postMessage({ type: "connectsuccess" }, "*");

                window.close();
            };

            var fail = function() {
                $("div.action-modal, section").removeClass("active");

                window.opener.postMessage({ type: "connectfail" }, "*");

                window.close();
            };

            var url = searchParams.get("site");

            if (!url) return fail();

            var siteConnections = localStorage.getItem("paymex-siteConnections") || [];

            if (siteConnections?.includes(url)) return success();

            siteConnections.push(url);

            localStorage.setItem("paymex-siteConnections", siteConnections);

            return success();
        };

        if (state.actionModal?.action == "transact") {
            Proton.sendTransaction(state, data.actions, data.idempotentKey).then(res => {
                window.idempotentKey = data.idempotentKey;

                window.opener.postMessage({ type: "txsuccess", tx: res }, "*");

                window.close();
            }).catch(error => {
                window.opener.postMessage({ type: "txfail", error: error }, "*");

                window.close();
            });
        };
    };

    return (
        <>
            {
                (state.w3aConnected) && (
                    <div className="action-modal">
                        <div className="content-wrapper">
                            <div className="site-overview">
                                <div className="img-wrapper">
                                    <img src={ data?.appLogo } alt="" />
                                </div>

                                <div className="text-wrapper">
                                    <p className="name">{ data?.appName }</p>
                                    <p className="url">{ data?.appSite }</p>
                                </div>
                            </div>

                            <div className="action-request">
                                { (state.actionModal?.action == "connect") ? "Connection Request" : "Transaction" }
                            </div>

                            <ul className="actions">
                                {
                                    (state.actionModal?.action == "connect") && (
                                        <>
                                            <li className="action">
                                                <div className="icon-wrapper">
                                                    <i className="fas fa-user-unlock"></i>
                                                </div>

                                                <div className="overview">
                                                    <p className="title">Account information</p>
                                                    <p className="description">By authorizing this connection request the website will be able to access your wallet information including your name, username, email address, and token balances.</p>
                                                </div>
                                            </li>

                                            <li className="action">
                                                <div className="icon-wrapper">
                                                    <i className="fas fa-key"></i>
                                                </div>

                                                <div className="overview">
                                                    <p className="title">Transaction requests</p>
                                                    <p className="description">By authorizing this connection request the website will be able to make transaction requests, further requiring your authorization.</p>
                                                </div>
                                            </li>
                                        </>
                                    )
                                }

                                {
                                    (state.actionModal?.action == "transact") && (
                                        <>
                                            {
                                                data.actions?.map(a => (
                                                    <li className="action">
                                                        <div className="icon-wrapper">
                                                            <i className="fas fa-key"></i>
                                                        </div>

                                                        <div className="overview">
                                                            <p className="title">{ a.account } - { a.name }</p>
                                                            {
                                                                Object.keys(a.data).map(d => (
                                                                    <p className="description">{ d }: { a.data[d] }</p>
                                                                ))
                                                            }
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </>
                                    )
                                }
                            </ul>

                            <button className="auth" onClick={ handleAuthorize }>Authorize</button>
                        </div>
                    </div>
                )
            }
        </>
    );
};
